<div class="form-group" [ngClass]="{'has-error': (model.control.touched && invalid  | async)}">
  <label class="form-label" *ngIf="label" [attr.for]="identifier" class="control-label">{{label}}</label>

  <input currencyMask type="tel" [id]="identifier" class="form-control" [(ngModel)]="value" [maxlength]="maxLength ? maxLength : null" [attr.placeholder]="placeholder ? placeholder : null" [ngStyle]="style"
         [disabled]="disabled" [options]="{ prefix: prefix, thousands: thousandsSeparator, decimal: decimalSeparetor, precision: precision, allowNegative: allowNegative }" [class.warning]="hasWarning"
         (blur)="onBlur()" (ngModelChange)="changeControlState()" />

  <validation-messages *ngIf="model.control.touched && invalid | async" [messages]="failures | async">
  </validation-messages>
</div>
