import { NgModule, LOCALE_ID, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

//External modules
// import { LocalStorageService, LocalStorageModule} from 'angular-2-local-storage';
import { ToastrModule } from "ngx-toastr";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { MessageService } from 'primeng/api';

//Internal modules
import { LayoutModule } from "./layout/layout.module";
import { SharedModule } from './shared/shared.module';

//Guards
import { AuthGuard } from './services/guards/auth.guard';
import { ProfileGuard } from './services/guards/profile.guard';
import { HasntProfileGuard } from './services/guards/hasnt-profile.guard';

//Services
import { UrlService } from "./services/shared/url.service";
import { ProfileService } from './services/profile.service';
import { AuthService } from "./services/authentication/auth.service";
import { DownloadService } from './services/download.service';
import { ErrorService } from './services/shared/error.service';
import { AppConfigService } from './app.config.service';

//Oidc client
const oidc_configuration = `${window.location.origin}/api/configuration/oidc`;
export function loadOidcConfig(configService: AppConfigService) {
  return () => configService.load(oidc_configuration);
}
//Interceptor
import { ErrorInterceptor } from './interceptor/error.interceptor';

// import ngx-translate and the http loader
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient} from '@angular/common/http';

import { AppComponent } from './app.component';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { SecureLayoutComponent } from './layout/secure-layout/secure-layout.component';
import { PublicLayoutComponent } from './layout/public-layout/public-layout.component';
import { UnauthorizedComponent } from './layout/unauthorized/unauthorized.component';
import { ForbiddenComponent } from './layout/forbidden/forbidden.component';
import { FuncionalidadePrecoGuard } from './services/guards/funcionalidade-preco.guard';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { FormModule } from './form/form.module';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from './components/components/components.module';

@NgModule({
  declarations: [
    AppComponent,
    AuthCallbackComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    BrowserAnimationsModule,
    SharedModule,
    HttpClientModule,
    FormModule,
    ComponentsModule,
    RouterModule.forRoot([
      { path: '', redirectTo: 'precos/cliente', pathMatch: 'full' },
      {
        path: '', component: SecureLayoutComponent, canLoad: [AuthGuard, ProfileGuard], canActivate: [AuthGuard, ProfileGuard], children: [
          { path: 'precos', canActivate: [FuncionalidadePrecoGuard], loadChildren: () => import('./features/precos/precos.module').then(m => m.PrecosModule) },
          { path: 'relatorio', loadChildren: () => import('./features/relatorios/relatorios.module').then(m => m.RelatoriosModule) },
          { path: 'gerenciamento-carteira', loadChildren: () => import('./features/gerenciamento-carteira/gerenciamento-carteira.module').then(m => m.GerenciamentoCarteiraModule) },
          { path: 'simulacao-preco', loadChildren: () => import('./features/simulacao-preco/simulacao-preco.module').then(m => m.SimulacaoPrecoModule) },
          { path: 'solicitacoes-acesso', loadChildren: () => import('./features/solicitacao-acesso/solicitacao-acesso.module').then(m=>m.SolicitacaoAcessoModule)},
          { path: 'grupos-gerenciais', loadChildren: () => import('./features/grupos-gerenciais/grupos-gerenciais.module').then(m=>m.GruposGerenciaisModule)},
          { path: 'precos-anteriores', loadChildren: () => import('./features/precos-anteriores/precos-anteriores.module').then(m=>m.PrecosAnterioresModule)},
          { path: 'preco-lote', loadChildren: () => import('./features/alteracao-preco-lote/alteracao-preco-lote.module').then(m=>m.AlteracaoPrecoLoteModule)},
          { path: 'acompanhamento-pedidos', loadChildren: () => import('./features/acompanhamento-pedidos/acompanhamento-pedidos.module').then(m => m.AcompanhamentoPedidosModule)},
          { path: 'forbidden', component: ForbiddenComponent },
        ]
      },
      {
        path: '', component: PublicLayoutComponent, canActivate: [AuthGuard, HasntProfileGuard], children: [
          { path: 'unauthorized', component: UnauthorizedComponent }
        ]
      },
      { path: 'auth-callback', component: AuthCallbackComponent },
      { path: '**', redirectTo: 'precos/cliente' }
    ], {}),
    LayoutModule,    
    ToastrModule.forRoot(),
    InfiniteScrollModule,
    CommonModule,
    // ngx-translate and the loader module
    HttpClientModule,
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    })
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
    MessageService,
    AuthService,
    // LocalStorageService,
    UrlService,
    ProfileService,
    DownloadService,
    ErrorService,
    { provide: APP_INITIALIZER, useFactory: loadOidcConfig, deps: [AppConfigService], multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },    
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

declare global {
  //interface Array<T> {
  //  pushSorted(element: T, comparer: any): Array<T>;
  //}

  class ClipboardItemClass {
    constructor(data: { [mimeType: string]: Blob });
  }

  interface Clipboard {
    write(element: any): any;
  }
}

//Array.prototype.pushSorted = function (el, compareFn) {
//  this.splice(
//    (function (arr) {
//      var m = 0;
//      var n = arr.length - 1;

//      while (m <= n) {
//        var k = (n + m) >> 1;
//        var cmp = compareFn(el, arr[k]);

//        if (cmp > 0) m = k + 1;
//        else if (cmp < 0) n = k - 1;
//        else return k;
//      }

//      return -m - 1;
//    })(this),
//    0,
//    el
//  );

//  return this.length;
//};
