import { Pipe, PipeTransform } from "@angular/core";

const PADDING = "000000";

@Pipe({ name: "numeric" })
export class NumericPipe implements PipeTransform {

    private decimalSeparator: string;
    private thousandsSeparator: string;

    constructor() {
        // TODO comes from configuration settings
        this.decimalSeparator = ",";
        this.thousandsSeparator = ".";
    }

    transform(value: number | string, fractionSize: number = 2): string {
        let [integer, fraction = ""] = (value || "").toString()
            .split(this.decimalSeparator);

        integer = integer.replace(/(\D)+/g, '');
        integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, this.thousandsSeparator);

        fraction = fraction.replace(/(\D)+/g, '');
        fraction = fractionSize > 0 && integer != ''
            ? this.decimalSeparator + (fraction + PADDING).substring(0, fractionSize)
            : "";

        

        return integer + fraction;
    }

    parse(value: string, fractionSize: number = 2): string {
        let [integer, fraction = ""] = (value || "").split(this.decimalSeparator);

        integer = integer.replace(this.thousandsSeparator, "");

        fraction = parseInt(fraction, 10) > 0 && fractionSize > 0
            ? this.decimalSeparator + (fraction + PADDING).substring(0, fractionSize)
            : "";

        return integer + fraction;
    }
}
