import { FormDateRangeComponent } from './form-daterange/form-daterange.component';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect';
import { FiltroBandeiraMultiSelectComponent } from './filtro-bandeira-multi-select/filtro-bandeira-multi-select.component';

import { SharedModule } from 'src/app/shared/shared.module';
import { MultiSelectModule } from 'primeng/multiselect';
import { FormMultiSelectComponent } from 'src/app/form/form-multi-select/form-multi-select.component';
import { FormModule } from 'src/app/form/form.module';


@NgModule({
  declarations: [
    FormDateRangeComponent,
    FiltroBandeiraMultiSelectComponent,    
  ],
  imports: [
    CommonModule,
    MultiselectDropdownModule,
    FormModule,
  ],
  exports: [
    FormDateRangeComponent,
    FiltroBandeiraMultiSelectComponent
  ],
})
export class ComponentsModule { }
