//Angular library
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

//Other library
// import { MyDatePickerModule } from 'mydatepicker';
import { TableModule } from "primeng/table";
import { InputMaskModule } from "primeng/inputmask";
import { AutoCompleteModule } from "primeng/autocomplete";
import { CurrencyMaskModule } from "ng2-currency-mask";
import { SharedModule } from "../shared/shared.module";
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

//Components
import { FormCheckboxComponent } from "./base-controls/form-checkbox/form-checkbox.component";
// import { FormDateComponent } from "./base-controls/form-date/form-date.component";
import { FormMaskComponent } from "./base-controls/form-mask/form-mask.component";
import { FormMoneyComponent } from "./base-controls/form-money/form-money.component";
import { FormMonthComponent } from "./base-controls/form-month/form-month.component";
import { FormNumericComponent } from "./base-controls/form-numeric/form-numeric.component";
import { FormPhoneComponent } from "./base-controls/form-phone/form-phone.component";
import { FormRadioComponent } from "./base-controls/form-radio/form-radio.component";
import { FormSelectComponent } from "./base-controls/form-select/form-select.component";
import { FormTextComponent } from "./base-controls/form-text/form-text.component";
import { FormTextAreaComponent } from "./base-controls/form-textarea/form-textarea.component";
import { ValidationMessages } from "./base-controls/validation/validation-messages.component";
import { FormMultiSelectComponent } from './form-multi-select/form-multi-select.component';
import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    SharedModule,
    FormsModule,
    // MyDatePickerModule,
    InputMaskModule,
    TableModule,
    AutoCompleteModule,
    CurrencyMaskModule,
    InfiniteScrollModule,
    MultiselectDropdownModule,
    SharedModule,
    CommonModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  declarations: [
    FormCheckboxComponent,
    // FormDateComponent,
    FormMaskComponent,
    FormMoneyComponent,
    FormMonthComponent,
    FormNumericComponent,
    FormPhoneComponent,
    FormRadioComponent,
    FormSelectComponent,
    FormTextComponent,
    FormTextAreaComponent,
    ValidationMessages,
    FormMultiSelectComponent,
    
  ],
  exports: [
    FormsModule,
    InfiniteScrollModule,
    FormCheckboxComponent,
    FormMaskComponent,
    FormMoneyComponent,
    FormMonthComponent,
    FormNumericComponent,
    FormPhoneComponent,
    FormRadioComponent,
    FormSelectComponent,
    FormTextComponent,
    FormTextAreaComponent,
    ValidationMessages    ,
    FormMultiSelectComponent,
  ]
})
export class FormModule { }
