import { Injectable } from '@angular/core';

import { AuthService } from './authentication/auth.service';

@Injectable()
export class ProfileService {

  private _adminRoles = ['consultor-admin', 'global-admin'];
  private _consultorRole = 'consultor-usuario';

  constructor(private _authService: AuthService) { }

  getIsAdmin() {
    const userData = this._authService.getUserData();

    if (userData && userData.role) {
      if (!Array.isArray(userData.role)) {
        return this._adminRoles.indexOf(userData.role) !== -1;
      }

      return userData.role.some(r => this._adminRoles.indexOf(r) !== -1);
    }

    return false;
  }

  async getIsConsultor() {
    const userData = this._authService.getUserData();
    const profile = await this._authService.getProfile();
    if (!profile || !profile.representanteId) {
      return false;
    }

    if (userData && userData.role) {
      if (!Array.isArray(userData.role)) {
        return userData.role === this._consultorRole;
      }

      return userData.role.some(r => r === this._consultorRole);
    }

    return false;
  }

  async getFuncionalidades() {
    const profile = await this._authService.getProfile();
    const isAdmin = this.getIsAdmin();
    if (!profile) {
      console.warn("profile vazio");
      return null;
    }
    if (profile.funcionalidadeIds == null || isAdmin) {
      if (profile.identityId == this._authService.getUserData().sub) {
        return null;//usuario logado
      } else {
        return profile.funcionalidadeIds;//usuario substituto
      }
    }

    return profile.funcionalidadeIds;
  }
}
