import { Component, Input, Optional, Inject, ViewChild, EventEmitter, Output } from '@angular/core';
import { NgModel, NG_VALUE_ACCESSOR, NG_VALIDATORS, NG_ASYNC_VALIDATORS } from '@angular/forms';
import 'rxjs'

import { ElementBase } from '../element-base/element-base';
import { NumericPipe } from '../../../pipes/numeric.pipe';

@Component({
  selector: 'form-numeric',
  templateUrl: "./form-numeric.component.html",
  styleUrls: ['./form-numeric.component.css'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: FormNumericComponent, multi: true },
    NumericPipe
  ],
})
export class FormNumericComponent extends ElementBase<number> {
  constructor(
    @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>
  ) {

    super(validators, asyncValidators);
  }

  @Input() public label: string;
  @Input() public maxLength?: number;
  @Input() public placeholder: string;
  @Input() public precision?: number = 0;
  @Input() public allowNegative?: boolean = false;
  @Input() public style?: Object;
  @Input() public disabled?: boolean;
  @Input() public hasWarning?: boolean;
  @Output() public change = new EventEmitter();

  decimalSeparetor: string = ',';
  thousandsSeparator: string = '.';
  prefix: string = '';
  alterado = false;

  public identifier = `form-numeric-${identifier++}`;

  onBlur() {
    if (this.alterado) {
      this.change.emit(true);
      this.alterado = false;
    }
  }

  changeControlState() {
    this.alterado = true;
  }

  @ViewChild(NgModel, { static: true }) model: NgModel;
}

let identifier = 0;
