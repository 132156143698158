import { Pipe, Injectable, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filtroMenu',
    pure: false
})

@Injectable()
export class FiltroMenuPipe implements PipeTransform {

    transform(itens: any[], filtro: string): any {

        return itens.filter(item => {
            if (item.descricao.toLowerCase().indexOf(filtro.toLowerCase()) >= 0)
                return true;

            if (item.submenu) {

                for (let submenuItem of item.submenu) {
                    if (submenuItem.descricao.toLowerCase().indexOf(filtro.toLowerCase()) >= 0) {
                        return true;
                    }
                }
            }

            return false;
        });
    }
}
