import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router } from '@angular/router';

import { AuthService } from '../../services/authentication/auth.service';
import { ProfileService } from '../profile.service';

@Injectable({
  providedIn: 'root'
})
export class FuncionalidadePrecoGuard implements CanActivate, CanLoad {

  constructor(private _profileService: ProfileService, private _authService: AuthService, private _router: Router) { }

  canActivate() {
    return this.checkUser();
  }

  canLoad() {
    return this.checkUser();
  }

  async checkUser() {
    var funcionalidades = await this._profileService.getFuncionalidades()
    if (funcionalidades == null)
      return true;
    if (funcionalidades.indexOf('preco') === -1) {
      this._router.navigate(['relatorio/limite-de-credito']);
    }
    return true;
  }
}
