<div class="container-fluid">
  <div class="row">
    <div id="header" class="page-navbar header-fixed">
      <ul class="nav navbar-nav">
        <li class="toggle-sidebar">
          <img id="img-logo" src="assets/img/banner-dislub.png" class="logo">
        </li>
      </ul>
      <div id="navbar-no-collapse" class="navbar-no-collapse">
        <ul class="nav navbar-nav navbar-right">
          <li class="dropdown">
            <a href="#" data-toggle="dropdown" style="float: left;" class="responsive">
              <span class="hidden-xs hidden-sm hidden-md">{{ usuario?.name }}</span>
              <i class="fa fa-user-circle ml5" style="font-size: 20px; float:none;" id="users"></i>
              <span class="sr-only">Usuário</span>
            </a>
            <ul class="dropdown-menu dropdown-menu-right dropdown-notification triangle" role="menu">
              <li>
                <a class="cursor-pointer" (click)="changePassword()"><i class="fa fa-key"></i> Alterar senha</a>
              </li>
              <li>
                <a class="cursor-pointer" (click)="signOut()"><i class="fa fa-power-off"></i> Sair</a>
              </li>
            </ul>
          </li>
        </ul>
      </div>

    </div>
  </div>
</div>
<div class="align-content-middle">
  <router-outlet></router-outlet>
</div>
