//Angular library
import { Component, Input } from '@angular/core';

@Component({
  selector: 'opt-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.css']
})
export class ContentComponent {

  constructor() { }

  //Vars
  @Input()
  header: string;
  @Input()
  minHeight: string = '600px';

}
