import { Component, Input, Optional, Inject, ViewChild } from '@angular/core';
import { NgModel, NG_VALUE_ACCESSOR, NG_VALIDATORS, NG_ASYNC_VALIDATORS } from '@angular/forms';
import 'rxjs'

import { ElementBase } from '../element-base/element-base';

@Component({
    selector: 'form-mask',
    templateUrl: "./form-mask.component.html",
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: FormMaskComponent, multi: true }
    ],
})
export class FormMaskComponent extends ElementBase<string> {
    constructor(
        @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
        @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>
    ) {

        super(validators, asyncValidators);
    }

    @Input() public label: string;
    @Input() public placeholder: string;
    @Input() public mask: string;
    @Input() public clearIfIncomplete: boolean = true;
    @Input() public submitted: boolean = false;
    @Input() public disabled: boolean = false;

    public identifier = `form-mask-${identifier++}`;

    @ViewChild(NgModel, { static: true }) model: NgModel;
}

let identifier = 0;
