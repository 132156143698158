import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class ErrorService {

    constructor(private router: Router) { }

    handleUnexpectedHttpError(error: any): Promise<any> {

        switch (error.status) {
            case 401:
                this.unauthorized();
                break;
            case 403:
                this.forbidden();
                break;
            case 404:
                this.notFound();
                break;
            case 500:
                this.serverError();
                break;
            default:
                this.genericError();
                break;
        }

        return Promise.reject(error.message || error);
    }

    private forbidden() {

        this.router.navigate(['/login']);
    }

    private unauthorized() {

        this.router.navigate(['/401']);
    }

    private notFound() {

        //this.router.navigate(['/404']);
    }

    private serverError() {

        console.log('server error');

        this.router.navigate(['/500']);
    }

    private genericError() {

        this.router.navigate(['/erro']);
    }
}
