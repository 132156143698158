import { Pipe, Injectable, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filtroSubmenu',
    pure: false
})

@Injectable()
export class FiltroSubmenuPipe implements PipeTransform {

    transform(itens: any[], filtro: string, itemPai: any): any {

        if (itemPai.descricao.toLowerCase().indexOf(filtro.toLowerCase()) >= 0) {
            return itens;
        }

        return itens.filter(item => {
            if (item.descricao.toLowerCase().indexOf(filtro.toLowerCase()) >= 0) {
                return true;
            }

            return false;
        });
    }
}