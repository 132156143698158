import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IMultiSelectOption } from 'angular-2-dropdown-multiselect';
import { Bandeira } from 'src/app/shared/models/bandeira';
import { forkJoin } from 'rxjs';
import { BandeiraService } from 'src/app/services/bandeira.service';


@Component({
  selector: 'filtro-bandeira-multi-select',
  templateUrl: './filtro-bandeira-multi-select.component.html',
  styleUrls: ['./filtro-bandeira-multi-select.component.css']
})
export class FiltroBandeiraMultiSelectComponent {

  constructor(
    private bandeiraService: BandeiraService,
  ) { }

  bandeirasFiltradas: Bandeira[];
  bandeirasSelecionadas: string[] = [];
  bandeirasOptions: IMultiSelectOption[] = [];
  bandeiras: Bandeira[] = [];
  @Output() selecionarBandeira = new EventEmitter<string[]>();
  @Input() categoriaPreSelecionada: string[] = [];

  ngOnInit() {
   this.getBandeira()
  }

  

  getBandeira(){
     //Inicializar Bandeiras
     forkJoin(
      this.bandeiraService.getBandeiras()
    ).subscribe(data => {
      console.log("data bandeira   :: ", data   )
      if (data && data[0])
        this.bandeiras = this.bandeiras.concat(data[0]);

      this.bandeirasOptions = this.bandeiras.map(b => {
        return {
          id: b.codigo,
          name: b.nome
        };
      });

      if(this.categoriaPreSelecionada[0]?.length){
        this.bandeirasSelecionadas = this.categoriaPreSelecionada;
      } else {
        this.bandeirasSelecionadas = this.bandeiras.map(b => b.codigo); }
     

      this.selecionarBandeira.emit(this.bandeirasSelecionadas);
    })
  }
}


