import { Component, OnInit, Input, Output } from '@angular/core';
import { EventEmitter, ViewChild, Optional, Inject } from "@angular/core";

//Other library
import { IMultiSelectOption, IMultiSelectSettings, IMultiSelectTexts } from 'angular-2-dropdown-multiselect';
import { NgModel, NG_VALUE_ACCESSOR, NG_VALIDATORS, NG_ASYNC_VALIDATORS } from '@angular/forms';
import { ElementBase } from '../base-controls/element-base/element-base';

@Component({
  selector: 'form-multi-select',
  templateUrl: './form-multi-select.component.html',
  styleUrls: ['./form-multi-select.component.css'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: FormMultiSelectComponent, multi: true }
  ],
})
export class FormMultiSelectComponent extends ElementBase<string> implements OnInit {
  constructor(
    @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>
  ) {
    super(validators, asyncValidators);
  }

  ngOnInit() {
    
      if (!this.artigoFeminino) {
        this.searchEmptyResult = 'Nenhum ' + this.labelContext + ' encontrado.';
        this.defaultTitle = 'Selecione um ' + this.labelContext;
        this.allSelected = 'Todos ' + this.labelContextPlural + ' selecionados';
      }
      else {
        this.searchEmptyResult = 'Nenhuma ' + this.labelContext + ' encontrada.';
        this.defaultTitle = 'Selecione uma ' + this.labelContext;
        this.allSelected = 'Todas ' + this.labelContextPlural + ' selecionadas';
      }

      this.multiSelectConfig();

  }

  //Vars
  @Input() public isMultiSelect: boolean;
  @Input() public label: string;
  @Input() public disabled?: boolean;
  @Input() public name?: string = '';
  @Input() public labelContext?: string = '';
  @Input() public labelContextPlural?: string = '';
  @Input() public submitted: boolean = false;
  @Output() public dropdownClosed = new EventEmitter<any[]>();
  @Input() public artigoFeminino: boolean;
  @Input() public maxHeight: string;

  private searchEmptyResult: string;
  private defaultTitle: string;
  private allSelected: string;

  //Options
  @Input() options: IMultiSelectOption[] = [];
  //Settings
  settings: IMultiSelectSettings;
  //Text configuration
  texts: IMultiSelectTexts;


  //Multi-select options
  multiSelectConfig() {
    this.settings = {
      enableSearch: true,
      checkedStyle: 'fontawesome',
      buttonClasses: 'btn btn-secondary btn-block btn-height',
      itemClasses: 'text-end',
      dynamicTitleMaxItems: 0,
      selectionLimit: 0,
      displayAllSelectedText: true,
      showCheckAll: true,
      showUncheckAll: true,
      maxHeight: this.maxHeight ? this.maxHeight : '250px'
    };

    this.texts = {
      checkAll: 'Marcar todos',
      uncheckAll: 'Desmarcar todos',
      checked: this.labelContext + ' selecionado',
      checkedPlural: this.labelContextPlural + ' selecionados',
      searchPlaceholder: 'Pesquisar',
      searchEmptyResult: this.searchEmptyResult,
      defaultTitle: this.defaultTitle,
      allSelected: this.allSelected
    }

    if (this.artigoFeminino) {
      this.texts.checked = this.labelContext + ' selecionada';
      this.texts.checkedPlural = this.labelContextPlural + ' selecionadas';
    }
  }

  AoFecharDropdown() {
    this.dropdownClosed.emit(null);
  }

  public identifier = `form-multi-select-${identifier++}`;

  @ViewChild(NgModel, { static: true }) model: NgModel;
}
let identifier = 0;
