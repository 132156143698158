import { Component, Input, Output, Optional, Inject, ViewChild, EventEmitter } from '@angular/core';
import { NgModel, NG_VALUE_ACCESSOR, NG_VALIDATORS, NG_ASYNC_VALIDATORS } from '@angular/forms';
import 'rxjs'

import { ElementBase } from '../element-base/element-base';

@Component({
  selector: 'form-checkbox',
  templateUrl: "./form-checkbox.component.html",
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: FormCheckboxComponent, multi: true }
  ],
})
export class FormCheckboxComponent extends ElementBase<boolean> {
  constructor(
    @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>
  ) {

    super(validators, asyncValidators);
  }

  @Input() public controlText: string;
  @Input() public controlValue: string;
  @Input() public disabled: boolean;
  @Input() public labelStyle: string;

  public identifier = `form-checkbox-${identifier++}`;

  @ViewChild(NgModel, { static: true }) model: NgModel;
}

let identifier = 0;
