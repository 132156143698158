<div class="page-wrapper">
  <div class="box-wrapper">
    <div class="error-content py-3">
      <h1 class="error-code text-primary font-weight-normal">Portal do Consultor</h1>
      <h3 class="text-muted mb-4">Acesso não autorizado <i class="fas fa-exclamation-triangle"></i></h3>
      <p class="text-muted mb-4 font-16">Desculpe, mas você não tem autorização para acessar este portal. Entre em contato com o CVI para solicitar o acesso.</p>
      <p class="text-muted mb-4 font-16">Caso tenha recebido acesso, será necessário desconectar e entrar novamente na aplicação.</p>
      <p class="text-muted">Clique <a class="cursor-pointer" (click)="logoff()">aqui</a> para desconectar da aplicação</p>
    </div>
  </div>
</div>
