import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import * as daterangepicker from 'daterangepicker';

@Component({
  selector: 'form-daterange',
  templateUrl: 'form-daterange.component.html',
  styleUrls: ['form-daterange.component.css']
})
export class FormDateRangeComponent implements OnInit {


  constructor() {
  }

  @ViewChild('daterange_input', { static: true }) element: ElementRef;
  input: daterangepicker;

  @Input() label: string;
  @Input() required: boolean = false;
  defaultOptions: daterangepicker.Options = {
    locale: {
      customRangeLabel: "Personalizado",
      applyLabel: "aplicar",
      cancelLabel: "cancelar",
      fromLabel: 'de',
      toLabel: 'até',
      format: "DD/MM/YYYY",
      separator: " - ",
      daysOfWeek: [
        "Dom",
        "Seg",
        "Ter",
        "Qua",
        "Qui",
        "Sex",
        "Sab"
      ],
      monthNames: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro"
      ]}

  };
  @Input() public drOptions: daterangepicker.Options;

  ngOnInit() {
    this.input = new daterangepicker(this.element.nativeElement, Object.assign(this.defaultOptions, this.drOptions));
  }
}
