//Angular library
import { Component, Input } from '@angular/core';

@Component({
  selector: 'opt-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css']
})
export class BannerComponent {

  constructor() { }

  //Vars
  @Input()
  bannerUrl: string;
}
