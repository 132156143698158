<!-- <ul class="nav-2-level sub show" [@openClose]="open ? 'open' : 'closed'" [@.disabled]="!!collapse"> -->
<ul class="nav-2-level sub show" [@openClose]="open ? 'open' : 'closed'" [@.disabled]="!!collapse">
  <!-- (click)="setMenuOpen(idx)" denovo para evitar que retraia ao clicar na subrota -->
  <li *ngFor="let submenuItem of submenu; let idx2 = index"
      (click)="setMenuOpen(idx2, $event)">
    <ng-container *ngIf="!submenuItem.linkExterno">
      <a href="#" [routerLink]="submenuItem.rota ? [submenuItem.rota] : []" [routerLinkActive]="'active'">
        <span class="txt">{{submenuItem.descricao}}</span>
      </a>
    </ng-container>
    <ng-container *ngIf="submenuItem.linkExterno">
      <a [attr.href]="submenuItem.linkExterno" [routerLinkActive]="'active'">
        <span class="txt">{{submenuItem.descricao}}</span>
      </a>
    </ng-container>

    <app-sub-menu [open]="menu && menu.open == idx2"
                  *ngIf="submenuItem.submenu && menu"
                  [menu]="menu.submenu"
                  [submenu]="submenuItem.submenu"></app-sub-menu>
  </li>
</ul>
