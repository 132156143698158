//Angular libray
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from "@angular/router";
import { NgxSpinnerModule } from 'ngx-spinner';

//Components
import { ContentComponent } from '../layout/content/content.component';
import { TitleComponent } from '../layout/title/title.component';
import { AutocompleteComponent } from './Components/autocomplete/autocomplete.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { MultiSelectModule } from 'primeng/multiselect';
import { FormsModule } from '@angular/forms';
import { SpinnerComponent } from './spinner/spinner.component';
import { NgSelectModule } from '@ng-select/ng-select';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    AutoCompleteModule,
    MultiSelectModule,
    NgSelectModule,
    NgxSpinnerModule
  ],
  declarations: [
    ContentComponent,
    TitleComponent,
    AutocompleteComponent,
    SpinnerComponent
  ],
  providers: [],
  exports: [
    CommonModule,
    RouterModule,
    ContentComponent,
    TitleComponent,
    AutocompleteComponent,
    SpinnerComponent,
    NgSelectModule,
    NgxSpinnerModule
  ]
})
export class SharedModule { }
