<!-- Label -->
<label class="form-label" *ngIf="label" [attr.for]="identifier">{{ label }}</label><br />

<!-- Multi-select component -->
<ss-multiselect-dropdown #multiselect 
[options]="options" 
[texts]="texts" 
[id]="identifier"
 name="dropdownSelect"
 [settings]="settings" 
 [(ngModel)]="value" 
 (ngModelChange)="AoFecharDropdown()" 
 [disabled]="disabled">
</ss-multiselect-dropdown>
