import { Component } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { finalize } from 'rxjs/operators';

import { AuthService } from '../../services/authentication/auth.service';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent {

  usuario: any;

  constructor(private _authService: AuthService) { }

  ngOnInit() {
    this.getUser();
  }

  getUser() {
    this.usuario = this._authService.getUserData();
  }

  logoff() {
    this._authService.get('profile/cache', new HttpParams().set('identityId', this.usuario.sub))
      .pipe(finalize(() => this._authService.signOut()))
      .subscribe({
        next: () => { },
        error: (error) => console.log('error', error)
      });
  }

}
