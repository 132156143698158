<div class="form-group" [ngClass]="{'has-error': ((submitted || model.control.touched) && invalid  | async)}">
    <label class="form-label" *ngIf="label" [attr.for]="identifier" class="control-label">{{label}}</label>
    <!--<span *ngIf="validators[0]._required">*</span>-->

    <select [id]="identifier" [(ngModel)]="value" class="form-control" [disabled]="disabled">
        <option value="" *ngIf="placeholder" disabled selected>{{placeholder}}</option>
        <ng-content></ng-content>
    </select>

    <validation-messages *ngIf="(submitted || model.control.touched) && invalid | async" [messages]="failures | async">
    </validation-messages>
</div>