//Angular library
import { Component, OnInit, Input } from '@angular/core';

//Class
import { MenuItem } from "../../shared/models/menu-item";

@Component({
  selector: 'opt-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent {

  constructor() { }

  //Vars
  @Input() menus: MenuItem[];
  
  _collapse: string;
  get collapse() {
    return this._collapse;
  }
  @Input('collapse') set collapse(value) {
    this._collapse = value;
    this.menu = {
      open: -1,
      submenu: {},
    };
  }

  pesquisaMenu: string = '';

  limpaPesquisa() {
    this.pesquisaMenu = '';
  }

  menu = {
    open: -1,
    submenu: {},
  };

  setMenuOpen(idx, e: MouseEvent) {
    e.stopPropagation();
    let menuOpen = this.menu.open;
    this.menu.open = menuOpen == -1 || menuOpen != idx ? idx : -1;
    this.menu.submenu = {};
  }
}
