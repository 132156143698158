import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';

import { ProfileService } from '../../services/profile.service';
import { MenuItem } from '../../shared/models/menu-item';
import { AuthService } from 'src/app/services/authentication/auth.service';
import { AppContext } from '../../app-context';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

declare var $: any;
declare var mainStart: any;

@Component({
  selector: 'app-secure-layout',
  templateUrl: './secure-layout.component.html',
  styleUrls: ['./secure-layout.component.css']
})
export class SecureLayoutComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    private profileService: ProfileService,
    private _router: Router,
    private _authService: AuthService,
    private _appContext: AppContext
  ) { }

  usuario: any;
  menu: MenuItem[];
  collapse;

  subscribeSubstituido: Subscription;

  async ngOnInit() {
    this.getUser();

    this.subscribeSubstituido = this._appContext.onUsuarioSelecionadoChanged()
      .subscribe(async usuario => {
        this.limparTela();
        this._authService.updateProfile();
        await this._authService.get('ui').subscribe(r => {
          this.menu = r;
        });

        const currentUrl = this._router.url;
        this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this._router.navigate([currentUrl]);
        });
      });
  }

  limparTela() {
    this.menu = [];
  }

  ngOnDestroy() {
    if (this.subscribeSubstituido) this.subscribeSubstituido.unsubscribe();
  }

  ngAfterViewInit() {
  }

  //chamarMainStart() {
  //  if ((!this.menu || this.menu.length == 0) || $('.toggle-sidebar').length <= 0) {
  //    setTimeout(() => {
  //      this.chamarMainStart();
  //    }, 100);
  //  } else {
  //    setTimeout(() => {
  //      //$.dynamic('body');
  //    }, 100);
  //  }
  //}

  getUser() {
    this.usuario = this._authService.getUserData();
  }
}
