import { Injectable } from '@angular/core';
import { CanActivate, CanLoad } from '@angular/router';

import { AuthService } from '../../services/authentication/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad {

  constructor(private _authService: AuthService) { }

  canLoad() {
    return this.checkUser();
  }

  canActivate() {
    return this.checkUser();
  }

  checkUser() {
    if (this._authService.getIsAuthorized()) {
      return true;
    }

    this._authService.signIn();
    return false;
  }
}
