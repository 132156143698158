import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { AuthService } from './authentication/auth.service';
@Injectable({
  providedIn: 'root'
})
export class BandeiraService {

  constructor(private authService: AuthService) { }

  getBandeiras(page?: number, pageSize?: number, codigo: string = "", nome: string = "") {
    var params = new HttpParams();
    if (page) params = params.set("page", page.toString());
    if (pageSize) params = params.set("pageSize", pageSize.toString());
    if (codigo) params = params.set("codigo", codigo);
    if (nome) params = params.set("nome", nome);
    return this.authService.get(`bandeira`, params);
  }
}
