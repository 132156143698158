import { Component } from '@angular/core';

import { AuthService } from '../../services/authentication/auth.service';

@Component({
  selector: 'app-forbidden',
  templateUrl: './forbidden.component.html',
  styleUrls: ['./forbidden.component.scss']
})
export class ForbiddenComponent {

  constructor(private _authService: AuthService) { }

}
