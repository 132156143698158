<opt-title [header]="header"></opt-title>

<div class="opt-container">
  <div class="opt-card-content">
    <div class="panel panel-default">
      <div class="panel-body" [ngStyle]="{'min-height': minHeight}">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>