//Angular library
import { NgModule } from '@angular/core';
import { FormsModule } from "@angular/forms";
import { SharedModule } from "../shared/shared.module";

//Components
import { MenuComponent } from "./menu/menu.component";
import { BannerComponent } from "./banner/banner.component";
import { UserComponent } from "./user/user.component";
import { DynamicComponent } from './dynamic/dynamic.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { ForbiddenComponent } from './forbidden/forbidden.component';
//Pipes
import { FiltroMenuPipe } from "../pipes/filtro-menu.pipe";
import { FiltroSubmenuPipe } from "../pipes/filtro-submenu.pipe";
import { NumericPipe } from "../pipes/numeric.pipe";

//Services
import { SecureLayoutComponent } from './secure-layout/secure-layout.component';
import { PublicLayoutComponent } from './public-layout/public-layout.component';
import { SubMenuComponent } from './menu/sub-menu/sub-menu.component';

@NgModule({
  imports: [
    FormsModule,
    SharedModule
  ],
  declarations: [
    MenuComponent,
    BannerComponent,
    UserComponent,
    DynamicComponent,
    UnauthorizedComponent,
    ForbiddenComponent,
    FiltroMenuPipe,
    FiltroSubmenuPipe,
    NumericPipe,
    SecureLayoutComponent,
    PublicLayoutComponent,
    SubMenuComponent
  ],
  exports: [
    MenuComponent,
    BannerComponent,
    UserComponent
  ],
  providers: []
})
export class LayoutModule { }
