import { Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AuthService } from './services/authentication/auth.service';
import { UserManager } from 'oidc-client';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  constructor(private _http: HttpClient) { }

  load(app_configuration: string) {
    return lastValueFrom(this._http.get(`${app_configuration}`))
      .then(config => {
        AuthService.manager = new UserManager(config);
      });
  }
}

